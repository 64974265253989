<nav class="n-section-menu">
  <div class="navHeader" [class.navHeaderHome]="isHome" [class.navHeaderScrolled]="isScrolled" [class.navHeaderExpanded]="isExpanded">

    <div class="container">
      <div class="row">
        <div class="col-6 col-md-6">
            <a class="n-list-box-logo" [routerLink]="'/'" [routerLinkActive]="'active'" title="Home Page">
              <img *ngIf="isHome && !isScrolled && !isExpanded" src="/assets/style/SkyeScenicToursLogoWhite.png" title="Skye Scenic Tours" alt="Skye Scenic Tours Logo" />
              <img *ngIf="isHome && (isScrolled || isExpanded)" src="/assets/style/SkyeScenicToursLogo.png" title="Skye Scenic Tours" alt="Skye Scenic Tours Logo" />
              <img *ngIf="!isHome" src="/assets/style/SkyeScenicToursLogo.png" title="Skye Scenic Tours" alt="Skye Scenic Tours Logo" />
            </a>
        </div>
        <div class="col-6 col-md-6">
          <a class="n-list-box-min-menu" href="#" role="button" (click)="toggle($event)" title="Navigation">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span class="sr-only">Navigation</span>
          </a>
        </div>
      </div>
    </div>
    
  </div>
  <div *ngIf="isExpanded" class="navContentExpanded">

    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="n-list-box-flush">
            <div class="n-list-box-content-flush">  
              <ul class="list-unstyled n-list-box-menu">
                <li><a (click)="toggle($event)" [routerLink]="'/'" [routerLinkActive]="'active'">Home</a></li>
                <li><a (click)="toggle($event)" [routerLink]="'/tours'" [routerLinkActive]="'active'">Tours</a></li>
                <li><a (click)="toggle($event)" [routerLink]="'/reviews'" [routerLinkActive]="'active'">Reviews</a></li>
                <li><a (click)="toggle($event)" [routerLink]="'/highlights'" [routerLinkActive]="'active'">Highlights</a></li>
                <li><a (click)="toggle($event)" [routerLink]="'/content/about'" [routerLinkActive]="'active'">About Us</a></li>
              </ul>  
            </div> 
          </div>
        </div>
        <div class="col-md-6">
          <div class="n-list-box-flush">
            <div class="n-list-box-content-flush">
              <h4>Contact</h4>
              <p>
                  Skye Scenic Tours<br />
                  Portree<br />
                  Flodigarry<br />
                  Isle of Skye<br />
                  IV51 9HZ<br />
              </p>
              <p>
                  Call 07707 831351
              </p>
              <p>
                <a (click)="toggle($event)" [routerLink]="'/content/contact'" [routerLinkActive]="'active'">Contact Us</a>
              </p>
              <p>
                <a href="https://www.tripadvisor.co.uk/Attraction_Review-g551883-d3485200-Reviews-Skye_Scenic_Tours-Portree_Isle_of_Skye_The_Hebrides_Scotland.html" target="_blank" rel="noreferrer">
                  <img src="/assets/style/icons8-black/icons8-tripadvisor-50.png" alt="Trip Advisor Logo" />
                  <span class="sr-only">Trip Advisor</span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> 
</nav>

