<footer class="n-section-darker n-section-footer">
    <div class="n-section-content-one">
        <div class="container">
            <div class="row">
                <div class="col-sm-6 col-md-3">
                    <div class="n-list-box-flush">
                        <div class="n-list-box-flush-content">
                            <h4>Contact</h4>
                            <p>
                                Skye Scenic Tours<br />
                                Portree<br />
                                Flodigarry<br />
                                Isle of Skye<br />
                                IV51 9HZ<br />
                            </p>
                            <p>
                                Call 07707 831351
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3">
                    <div class="n-list-box-flush">
                        <div class="n-list-box-flush-content">                    
                            <h4>Be Social</h4>
                            <p>
                                <a href="https://www.tripadvisor.co.uk/Attraction_Review-g551883-d3485200-Reviews-Skye_Scenic_Tours-Portree_Isle_of_Skye_The_Hebrides_Scotland.html" target="_blank" rel="noreferrer" title="Skye Scenic Tours on Trip Advisor">Trip Advisor</a><br/>
                                <a href="https://www.facebook.com/pages/category/Tour-Guide/Skye-Scenic-Tours-183776902009211/" target="_blank" rel="noreferrer" title="Skye Scenic Tours on Facebook">Facebook</a><br/>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3">
                    <div class="n-list-box-flush">
                        <div class="n-list-box-flush-content">
                            <h4>Certificates</h4>
                            <a [routerLink]="'/blogs/covid-certification-arrived'" [routerLinkActive]="'active'">Covid Secure</a><br/>

                            <!--                 
                            <div id="TA_certificateOfExcellence752" class="TA_certificateOfExcellence">
                                <ul id="nas4a6mw5Y" class="TA_links U85fVMqvYS">
                                    <li id="YLFqluwJeZW6" class="zOeDDzx">
                                        <a target="_blank" href="https://www.tripadvisor.co.uk/Attraction_Review-g551883-d3485200-Reviews-Skye_Scenic_Tours-Portree_Isle_of_Skye_The_Hebrides_Scotland.html"><img src="https://www.tripadvisor.co.uk/img/cdsi/img2/awards/CoE2017_WidgetAsset-14348-2.png" alt="TripAdvisor" class="widCOEImg" id="CDSWIDCOELOGO" />
                                    </li>
                                </ul>
                            </div>
                            <script async src="https://www.jscache.com/wejs?wtype=certificateOfExcellence&amp;uniq=752&amp;locationId=3485200&amp;lang=en_UK&amp;year=2017&amp;display_version=2"></script> 
                            -->
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3">
                    <div class="n-list-box-flush">
                        <div class="n-list-box-flush-content">
                            <h4>Good to Know</h4> 

                            <a [routerLink]="'/content/privacy-policy'" [routerLinkActive]="'active'">Privacy Policy</a><br/>
                            <a [routerLink]="'/content/cookie-policy'" [routerLinkActive]="'active'">Cookie Policy</a><br/>
                            <a [routerLink]="'/content/terms-and-conditions'" [routerLinkActive]="'active'">Terms &amp; Conditions</a><br/>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3">
                    <div class="n-list-box-flush">
                        <div class="n-list-box-flush-content">
                            <h4>Credits</h4> 
                            <a href="https://icons8.com" target="_blank" rel="noreferrer">Icons by Icons8</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="n-list-box-flush">
                        <div class="n-list-box-flush-content">
                            <p>&copy; 2012 - 2024 Skye Scenic Tours</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>